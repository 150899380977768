<!-- 接龙模版路由页面 -->
<template>
  <Layout>
    <div style="text-align: center; justify-content: center">
      <div
        class="base-class"
        style="text-align: center; justify-content: center"
      >
        <div style="background-color: white; width: 100%; text-align: left">
          <div class="search-item">
            <el-button style="height: 70px;background-color: #f8f8f8;border: 0px;" @click="creatClearJL()"
              ><div style="display: flex;">
                <i class="el-icon-plus" style="font-size: 22px;margin: 10px;color: #333333;"></i>
                <p style="margin: 15px;margin-left: 0px;margin-right: 5px;color: #1877ff;font-weight: 400;">创建空白接龙</p>
              </div></el-button
            >

            <div
              style="
                display: flex;
                background-color: #f8f8f8;
                width: 600px;
                border-radius: 20px;
                height: 50px;
                margin-top: 10px;
                margin-left: 10px;
              "
            >
              <div>
                <i
                  class="el-icon-search"
                  style="
                    font-size: 25px;
                    margin-left: 18px;
                    margin-top: 10px;
                    color: #8e89aa;
                  "
                ></i>
              </div>
              <el-input
                placeholder="输入搜索关键词"
                v-model="filterName"
                class="input-with-search"
                clearable
              >
              </el-input>
              <el-button
                icon="el-icon-search"
                class="search-button"
                @click="searchClick()"
              ></el-button>
            </div>
          </div>

          <div
            class="base-class"
            style="margin-top: 10px; margin-bottom: 0px; margin-left: 30px"
          >
            <el-tabs v-model="classifyName" @tab-click="handleClick">
              <el-tab-pane
                v-for="item in mstencilArray"
                :key="item.classifyName"
                :label="item.classifyName"
                :name="item.classifyName"
              ></el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <div class="base-class" style="margin-top: 10px; margin-bottom: 10px">
        <div
          v-for="(item, index) in secondMstencilArray"
          :key="item.id"
          class="sub-tab-item"
          @click="secondClick(item, index)"
          :class="{ secondActive: currentSecondIndex == index }"
        >
          {{ item.classifynamelast }}
        </div>
      </div>

      <div class="base-class" style="display: block; min-height: 400px">
        <div>
          <div style="display: flex; flex-wrap: wrap">
            <div
              class="model-item"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <el-image
                class="click-image"
                :src="imageUrl + item.cover_img"
                style="
                  background-color: bisque;
                  margin: 11px;
                  border-radius: 3px;
                "
              ></el-image>

              <div style="width: 200px" @click="creatClick(item)">
                <p class="model-item-title">{{ item.name }}</p>
                <p class="model-item-count">{{ item.auxiliarytitle }}</p>
              </div>
              <div class="free-button" @click="creatClick(item)">免费使用</div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        layout="sizes,prev, pager, next,total"
        :page-sizes="[20, 50, 100, 200]"
        :total="tablePage.total"
        :current-page="tablePage.pageNum"
        :page-size="tablePage.pageSize"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </Layout>
</template>
    <script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import {
  getTempleList,
  getFirstTempleType,
  getTwoTempleType,
} from "@/utils/api/activity";
import { imageUrl } from "@/utils/config/commonUrl.js";

// import { getUserInfo } from "@/utils/api/user";
/**
 * Basic-table component
 */
export default {
  page: {
    title: "接龙模版",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      imageUrl: imageUrl,
      filterName: "",

      mstencilArray: [],

      secondMstencilArray: [],

      classifyName: "全部模版", //一级
      classifynamelast: "", //二级
      tableData: [],

      tablePage: {
        pageNum: 1, //
        pageSize: 20,
        total: 0,
      },
      currentSecondIndex: null, //二级分类索引
        isType: 106,
    };
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.replace("/login");
    }

    this.getList();

    this.getFirstTempleType();
  },

  methods: {

    //创建空白接龙
    creatClearJL(){

      console.log('创建空白接龙');
        if (this.isType == 109){
            this.$router.push({
                path: "/solitaire",
                query: { activityId: 2, selectType: "add", isType: 109},
            });
        }
        if (this.isType == 110){
            this.$router.push({
                path: "/solitaire",
                query: { activityId: 2, selectType: "add", isType: 110},
            });
        }
        if (this.isType == 106){
            this.$router.push({
                path: "/solitaire",
                query: { activityId: 2, selectType: "add", isType: 106},
            });
        }

    },
    handleSizeChange(pageSize) {
      this.tablePage.pageNum = 1;
      this.tablePage.pageSize = pageSize;
      this.getList();
    },
    handlePageChange(currentPage) {
      this.tablePage.pageNum = currentPage;
      this.getList();
    },
    //搜索方法
    searchClick() {
      this.getList();
    },

    getList(templeTypeId) {
      //获取列表数据
      getTempleList({
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.pageNum,
        keyword: this.filterName,
        classifyName: this.classifyName == "全部模版" ? "" : this.classifyName,
        classifynamelast: this.classifynamelast,
        templeTypeId: templeTypeId ? templeTypeId : "",
      }).then((res) => {
        console.log(res, "请求列表数据");
        if (res.status == 1) {
          this.tableData = res.data.list;
          this.tablePage.total = res.data.total;
        }
      });
    },

    // 获取一级分类
    getFirstTempleType() {
      getFirstTempleType({
        activityBigType: 1,
      }).then((res) => {
        console.log(res, "一级分类数据");
        if (res.status == 1) {
          this.mstencilArray.push({
            classifyName: "全部模版",
          });

          for (const key in res.data) {
            let item = res.data[key];
            this.mstencilArray.push(item);
          }
        }
      });
    },

    // 一级分类点击方法
    handleClick(tab, event) {
      console.log(tab, event,'标签点击事件');
      console.log(this.classifyName, "classifyNameclassifyName");

      this.classifynamelast = "";

      this.tablePage.pageNum = 1;

      this.currentSecondIndex = null;

      this.getList();

      this.getTwoTempleType();
      if (this.classifyName == '签到打卡'){
          this.isType = 109
      } else {
          this.isType = 106
      }
    },

    // 获取二级分类
    getTwoTempleType() {
      getTwoTempleType({
        activityBigType: 1,
        classifyName: this.classifyName,
      }).then((res) => {
        console.log(res, "二级分类数据");
        if (res.status == 1) {
          this.secondMstencilArray = res.data;
        }
      });
    },

    // 二级分类点击方法
    secondClick(item, index) {
      console.log(item);
      this.currentSecondIndex = index;
      this.classifynamelast = item.classifynamelast;
      this.getList(item.id);
    },

    // 创建自定义接龙
    creatClick(item) {
      // console.log(item, "item");
      //   this.$router.push({
      //       path: "/solitaire",
      //       query: { activityId: item.id, selectType: "add", isType: item.activity_type,is_template:1},
      //   });

      // eslint-disable-next-line no-case-declarations
      let routeData = this.$router.resolve({
        path: "/solitaire",
        query: {
          activityId: item.id,
          selectType: "add",
          isType: item.activity_type,
          is_template: 1,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
    <style lang="scss" scoped>
.base-class {
  display: flex;
  min-width: 1236px;
}
.top-item {
  width: 397px;
  height: 120px;
  margin-right: 15px;
}
.top-image {
  width: 397px;
  height: 120px;
  position: absolute;
  z-index: -1;
}
.top-title {
  color: #ffffff;
  line-height: 33px;
  font-size: 24px;
  font-weight: 400;
  margin-left: 31px;
  margin-top: 18px;
  margin-bottom: 5px;
}

.top-count {
  color: #ffffff;
  line-height: 22px;
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 13px;
}
.creat-text {
  color: #ffffff;
  line-height: 22px;
  text-align: right;
  font-size: 14px;
  margin-right: 10px;
}

.click-item {
  width: 235px;
  height: 93px;
  margin-right: 12px;
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 3px;
  display: flex;
}
.click-image {
  width: 66px;
  height: 66px;
  margin-top: 13px;
  margin-left: 22px;
}

.click-title {
  font-size: 19px;
  color: #2b2f36;
  line-height: 28px;
  margin-top: 21px;
  margin-bottom: 12px;
  margin-right: 5px;
}

.click-creat {
  color: #a2a2a2;
  line-height: 20px;
  font-size: 13px;
}

.model-top {
  justify-content: space-between;
  display: flex;
  width: 1220px;
  margin-top: 20px;
}
.model-top-title {
  color: #2b2f36;
  line-height: 21px;
  font-size: 16px;
  font-weight: 500;
}
.model-top-more {
  color: #686868;
  line-height: 19px;
  font-size: 14px;
}
.model-item {
  width: 400px;
  height: 88px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.model-item-title {
  color: #000000;
  line-height: 22px;
  font-size: 17px;
  margin-top: 18px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-weight: 400;
}
.model-item-count {
  color: #676767;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.free-button {
  cursor: pointer;
  width: 80px;
  height: 31px;
  background-color: white;
  border-radius: 18px;
  border: solid 1px #1877ff;
  text-align: center;
  line-height: 31px;
  color: #1877ff;
  font-size: 13px;
  margin-top: 30px;
  margin-left: 10px;
}
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}
::v-deep .el-tabs__item {
  color: #3f3f3f;
  font-weight: 400;
}
::v-deep .el-tabs__item.is-active {
  color: #1877ff;
  font-weight: 500;
}
::v-deep .el-tabs__item:hover {
  color: #1877ff;
}
::v-deep .el-tabs__active-bar {
  // width: 80px;
  height: 3px;
  border-radius: 4px;
}

::v-deep .el-tabs__active-bar.is-top {
  padding: 0 8px;
  border-radius: 4px;
  box-sizing: border-box !important;
  background-clip: content-box !important;
}

.sub-tab-item {
  text-align: center;
  background-color: #eeeeee;
  color: #909090;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  cursor: pointer;
}
.search-item {
  margin-left: 20px;
  margin-top: 15px;
  // background-color: #f8f8f8;
  width: 600px;
  height: 80px;
  border-radius: 20px;
  display: flex;
}
.search-button {
  width: 64px;
  height: 40px;
  background-color: #1877ff;
  font-size: 25px;
  color: white;
  border-radius: 30px;
  text-align: center;
  line-height: 25px;
  padding-top: 7px;
  margin-top: 5px;
}
.input-with-search {
  margin-left: 5px;
  margin-top: 5px;
  width: 483px;
}
.input-with-search {
  :deep(.el-input__inner) {
    border: 0px solid #dcdfe6;
    background-color: #f8f8f8;
  }
}
.secondActive {
  background: #d7e7ff;
  border: 2rpx solid #b8d5ff;
  color: #1877ff;
}
// el-input__inner

//选中 #B8D5FF   #1877FF
</style>