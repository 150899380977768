import serviceAxios from "../request.js";


// 获取接龙模版列表
export const getHomePageTempleList = (data) => {
  return serviceAxios({
    url: "/activity/getHomePageTempleList",
    method: "post",
    data,
  });
};


// 发布记录列表

export const getCreateList = (data) => {
  return serviceAxios({
    url: "/mine/getCreateList",
    method: "post",
    data,
  });
};


// 获取小程序接龙码

export const getActivityCode = (data) => {
  return serviceAxios({
    url: "/activity/getActivityCode",
    method: "post",
    data,
  });
};


// 提前结束

export const earlyTermination = (data) => {
  return serviceAxios({
    url: "/activity/earlyTermination",
    method: "post",
    data,
  });
};

// 重启活动

export const restartActivity = (data) => {
  return serviceAxios({
    url: "/activity/restartActivity",
    method: "post",
    data,
  });
};

// 立即开始

export const startImmediately = (data) => {
  return serviceAxios({
    url: "/activity/startImmediately",
    method: "post",
    data,
  });
};

// 删除接口

export const delActivity = (data) => {
  return serviceAxios({
    url: "/mine/delActivity",
    method: "post",
    data,
  });
};
// 获取分类
export const getFirstTempleType = (data) => {
  return serviceAxios({
    url: "/activity/getFirstTempleType",
    method: "post",
    data,
  });
};

// 获取二级分类
export const getTwoTempleType = (data) => {
  return serviceAxios({
    url: "/activity/getTwoTempleType",
    method: "post",
    data,
  });
};



// /activity/getTempleList 获取全部模版
export const getTempleList = (data) => {
  return serviceAxios({
    url: "/activity/getTempleList",
    method: "post",
    data,
  });
};



// 接龙报名列表


export const getSignList = (data) => {
  return serviceAxios({
    url: "/activity/pc/getSignList",
    method: "post",
    data,
  });
};


// 获取报名用户详细信息 

export const sleDetails = (data) => {
  return serviceAxios({
    url: "/activity/sleDetails",
    method: "post",
    data,
  });
};


// 修改报名信息接口 

export const updateSignInfo = (data) => {
  return serviceAxios({
    url: "/activity/updateSignInfo",
    method: "post",
    data,
  });
};

// 获取是否是管理员

export const getProjectManageAdmin = (data) => {
  return serviceAxios({
    url: "/activity/getProjectManageAdmin",
    method: "post",
    data,
  });
};

// 点评

export const updatecommentlanguage = (data) => {
  return serviceAxios({
    url: "/activity/updatecommentlanguage",
    method: "post",
    data,
  });
};



// 不允许(允许)他人查看

export const updateSignInfoLook = (data) => {
  return serviceAxios({
    url: "/activity/updateSignInfo",
    method: "post",
    data,
  });
};


// 删除报名信息



export const signDel = (data) => {
  return serviceAxios({
    url: "/activity/signDel",
    method: "post",
    data,
  });
};
// 获取投票链接
export const getShareLink = (data) => {
  return serviceAxios({
    url: "/activity/getShareLink",
    method: "post",
    data,
  });
};